import { lazyLoad } from './utils'
import { CustomRouteObject } from './types'

// ----------- Pages -----------
const SubscriptionPlans = lazyLoad(() => import(/* webpackChunkName: 'subscription-plans' */ '~/pages/subscriptions/index'))
const SubscriptionDetails = lazyLoad(() => import(/* webpackChunkName: 'subscription-details' */ '~/pages/SubscriptionDetails'))

export const SUBSCRIPTION_PLANS_ROUTE = '/subscriptions'
export const SUBSCRIPTION_DETAILS_ROUTE = '/subscriptions/:planId'

export const subscriptionRoutes: CustomRouteObject[] = [
  {
    path: SUBSCRIPTION_PLANS_ROUTE,
    element: <SubscriptionPlans />,
  },
  {
    path: SUBSCRIPTION_DETAILS_ROUTE,
    element: <SubscriptionDetails />,
  },
]
